import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/n1yb9D1Pw1w">
    <SEO title="Living in Babylon - Living in Babylon" />
  </Layout>
)
export default SermonPost
